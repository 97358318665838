<template>
  <v-container>
    <v-card class="pa-5">
      <div v-if="searching">
        <p class="text-center mt-5">
          <v-icon>fas fa-atom fa-spin</v-icon> Carregando informações ...
        </p>
      </div>
      <joker />
      <v-card-title>
        Grupos de regras
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchGroup"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-btn
          :disabled="searching"
          color="success"
          class="ml-5"
          @click="openNewGroup()"
          ><v-icon>fas fa-plus</v-icon></v-btn
        >
      </v-card-title>
      <v-data-table
        v-if="groups"
        style="cursor: pointer;"
        :headers="headers.groups"
        :items="groups"
        :sort-by="['id']"
        :sort-desc="[false]"
        item-key="id"
        class="elevation-1"
        :search="searchGroup"
        @click:row="openItem"
      >
        <template v-slot:item.active="{ item }">
          <span>{{ item.active ? 'SIM' : 'NÃO' }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="blue"
                icon
                v-on="on"
                @click.stop="openEditGroup(item)"
              >
                <v-icon>fas fa-edit</v-icon></v-btn
              >
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.viewGroup" max-width="900">
      <v-card v-if="selectedGroup">
        <v-card-title class="headline" primary-title>
          Lista de regras ~{{ selectedGroup.name }}~
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                :disabled="searching"
                color="success"
                class="ml-5"
                @click="addNewAlert()"
                ><v-icon>fas fa-plus</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="12"
              class="ma-0 pa-0"
              v-for="(alert, ixA) in selectedGroup.alerts"
              :key="`alert_${ixA}_${alert.id}`"
              :style="alert.delete ? 'background-color: #ff4b4b;' : ''"
            >
              <v-divider></v-divider>
              <table width="100%">
                <tr>
                  <td>
                    <p class="title pa-0 my-1">
                      <b>Tipo de regra</b>:
                      <v-select
                        :items="alertTypes"
                        item-text="text"
                        item-value="id"
                        v-model="alert.type"
                        class="title"
                        style="width: 300px; display: inline-block;"
                      ></v-select>
                    </p>

                    <p class="title pa-0 my-1">
                      <v-icon v-if="alert.type === 0">fas fa-font</v-icon>
                      <v-icon v-if="alert.type === 1"
                        >fas fa-search-plus</v-icon
                      >
                      <v-icon v-if="alert.type === 2"
                        >fas fa-dollar-sign</v-icon
                      >

                      [<v-select
                        :items="logics"
                        v-model="alert.logic"
                        class="title"
                        style="width: 75px; display: inline-block;"
                      ></v-select
                      >]

                      <span v-if="alert.type === 0"
                        ><i><u>NOME DO PRODUTO LIKE</u></i></span
                      >
                      <span v-if="alert.type === 1"
                        ><i><u>NOVOS PRODUTOS</u></i></span
                      >
                      <span v-if="alert.type === 2"
                        ><i><u>PREÇO ATUAL</u></i></span
                      >
                      <span v-if="alert.type === 3"
                        ><i><u>PREÇO ATUAL</u></i></span
                      >
                      <span v-if="alert.type === 4"
                        ><i><u>PREÇO ANTERIOR</u></i></span
                      >

                      <span v-if="alert.type === 0">
                        [<v-text-field
                          v-model="alert.filter_name"
                          label="Termo %"
                          class="title"
                          style="width: 300px; display: inline-block;"
                        ></v-text-field
                        >]
                      </span>

                      <span
                        v-if="
                          alert.type === 2 ||
                            alert.type === 3 ||
                            alert.type === 4
                        "
                      >
                        [<v-select
                          :items="priceOperators"
                          item-text="text"
                          item-value="id"
                          v-model="alert.price_operator"
                          class="title"
                          style="width: 140px; display: inline-block;"
                        ></v-select
                        >]
                        <span v-if="alert.filter_price">
                          R$[<v-text-field
                            v-model="alert.filter_price"
                            type="number"
                            clearable
                            class="title"
                            style="display: inline-block;"
                          ></v-text-field
                          >]
                        </span>
                        <span v-else-if="alert.type === 2"
                          >[PREÇO ANTERIOR]
                          <v-btn color="blue" @click="alert.filter_price = 50"
                            >alterar</v-btn
                          ></span
                        >
                        <span v-else-if="alert.type === 3"
                          >[PREÇO MINIMO]
                          <v-btn color="blue" @click="alert.filter_price = 50"
                            >alterar</v-btn
                          ></span
                        >
                        <span v-else-if="alert.type === 4"
                          >[PREÇO ATUAL]
                          <v-btn color="blue" @click="alert.filter_price = 50"
                            >alterar</v-btn
                          ></span
                        >
                      </span>
                    </p>
                  </td>
                  <td class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="!alert.delete"
                          color="error"
                          icon
                          v-on="on"
                          @click="
                            alert.delete = !alert.delete
                            selectedGroup.alerts.push({})
                            selectedGroup.alerts.pop()
                          "
                        >
                          <v-icon>fas fa-trash</v-icon></v-btn
                        >
                        <v-btn
                          v-else
                          color="white"
                          icon
                          v-on="on"
                          @click="
                            alert.delete = !alert.delete
                            selectedGroup.alerts.push({})
                            selectedGroup.alerts.pop()
                          "
                        >
                          <v-icon>fas fa-undo</v-icon></v-btn
                        >
                      </template>
                      <span v-if="!alert.delete">Excluir</span>
                      <span v-else>Mudei de déia (não excluir)</span>
                    </v-tooltip>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="editOrSaveAlerts()">
                <v-icon class="mr-2">fas fa-save</v-icon> Salvar
                alterações</v-btn
              >
            </template>
            <span>Salvar alterações</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.newGroup" max-width="900">
      <v-card v-if="newGroup">
        <v-card-title class="headline" primary-title>
          Novo grupo ~{{ newGroup.name }}~
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="newGroup.name"
                label="Nome do grupo de regras"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-switch
                :label="`Ativo: ${newGroup.active ? 'SIM' : 'NÃO'}`"
                v-model="newGroup.active"
              ></v-switch>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="logics"
                v-model="newGroup.logic"
                label="Lógica"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="searching" color="success" @click="createNewGroup()"
            ><v-icon>fas fa-save</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.editGroup" max-width="900">
      <v-card v-if="selectedGroup">
        <v-card-title class="headline" primary-title>
          Editar grupo ~{{ selectedGroup.name }}~
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="selectedGroup.name"
                label="Nome do grupo de regras"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-switch
                :label="`Ativo: ${selectedGroup.active ? 'SIM' : 'NÃO'}`"
                v-model="selectedGroup.active"
              ></v-switch>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="logics"
                v-model="selectedGroup.logic"
                label="Lógica"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="searching" color="success" @click="editGroup()"
            ><v-icon>fas fa-save</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      @click="fetchGroups()"
      class="mr-3"
      style="margin-bottom: 50px"
      :disabled="searching"
      absolute
      fab
      bottom
      fixed
      right
      color="warning"
    >
      <v-icon>fas fa-sync</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
// @ is an alias to /src
import JokerVue from './../components/Joker'
export default {
  name: 'alerts',
  components: {
    joker: JokerVue
  },
  data() {
    return {
      alertTypes: [
        { id: 0, text: 'Comparador de nome' },
        { id: 1, text: 'Novos produtos' },
        { id: 2, text: 'Comparador de preço atual' },
        { id: 4, text: 'Comparador de preço anterior' },
        { id: 3, text: 'Comparador de preço mínimo' }
      ],
      priceOperators: [
        { id: 0, text: 'igual a' },
        { id: 1, text: 'menor que' },
        { id: 2, text: 'maior que' },
        { id: 3, text: 'diferente de' }
      ],
      logics: ['AND', 'OR'],
      searchGroup: null,
      groups: [],
      newGroup: {},
      selectedGroup: null,
      dialogs: {
        viewGroup: null,
        newGroup: null,
        editGroup: null,
        editAlert: null
      },
      searching: false,
      stores: [],
      headers: {
        groups: [
          { text: 'Nome', value: 'name' },
          { text: 'Lógica', value: 'logic' },
          { text: 'Ativo', value: 'active' },
          { text: 'Ações', value: 'actions' }
        ],
        alerts: [{ text: 'Nome', value: 'name' }]
      }
    }
  },
  mounted() {
    this.fetchGroups()
  },
  created() {},
  methods: {
    copyTestingCode(_elementID) {
      var vm = this
      let testingCodeToCopy = document.querySelector(`#${_elementID}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        if (document.execCommand('copy')) {
          vm.$snotify.success(`Texto copiado para a área de transferência`)
        } else {
          throw new Error(
            'Não foi possível copiar o texto para área de transferência. Copie manualmente'
          )
        }
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    fetchGroups() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `groupAlert/list`)
        .then(result => {
          if (result && result.data) {
            vm.groups = result.data
          }
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    fetchStores() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `stores/list`)
        .then(result => {
          if (result && result.data) {
            vm.stores = result.data
          }
        })
        .catch(error => {
          console.debug(error)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    editOrSaveAlerts() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request(
          'post',
          `alert/editOrSave/${vm.selectedGroup.id}`,
          vm.selectedGroup
        )
        .then(result => {
          if (result && result.data) {
            vm.selectedGroup = result.data
          }
          vm.groups.forEach(_group => {
            if (_group.id === vm.selectedGroup.id) {
              _group.alerts = vm.selectedGroup.alerts
            }
          })
          vm.$snotify.success('Alertas editados com sucesso!')
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
        })
    },
    openNewGroup() {
      var vm = this
      vm.newGroup = {
        name: 'novo grupo',
        logic: 'AND',
        active: true
      }
      vm.dialogs.newGroup = true
    },
    openEditGroup(group) {
      var vm = this
      vm.selectedGroup = group
      vm.dialogs.editGroup = true
    },
    addNewAlert() {
      var vm = this
      if (!vm.selectedGroup.alerts) {
        vm.selectedGroup.alerts = []
      }
      vm.selectedGroup.alerts.push({
        type: 0,
        logic: 'AND',
        filter_name: '%novo%filtro%',
        filter_price: null,
        price_operator: null,
        group_id: vm.selectedGroup.id
      })
    },
    createNewGroup() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `groupAlert/new`, vm.newGroup)
        .then(result => {
          if (result && result.data) {
            vm.groups.push(result.data)
          }
          vm.dialogs.newGroup = false
          vm.newGroup = {}
          vm.$snotify.success('Grupo criado com sucesso')
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
        })
    },
    editGroup() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request(
          'post',
          `groupAlert/edit/${vm.selectedGroup.id}`,
          vm.selectedGroup
        )
        .then(result => {
          if (result && result.data) {
            vm.selectedGroup = result.data
          }
          vm.dialogs.editGroup = false
          vm.groups.forEach(group => {
            if (group.id === vm.selectedGroup.id) {
              group = vm.selectedGroup
            }
          })
          vm.$snotify.success('Grupo editado com sucesso')
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
        })
    },
    openItem(_i) {
      var vm = this
      vm.selectedGroup = _i
      vm.dialogs.viewGroup = true
    }
  }
}
</script>
